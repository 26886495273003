import { APP_URL, APP_CONST, appName } from "@utils/index";
import * as schema from "react-structured-data";

const {
  JSONLD,
  Product,
  AggregateRating,
  GenericCollection,
  Review,
  Author,
  Location,
  Rating,
  Generic,
} = schema;

const SEO = () => {
  // https://blog.logrocket.com/improving-react-seo-structured-data
  // const reviewStructuredData = {
  //   "@context": "https://schema.org",
  //   "@type": "Review",
  //   itemReviewed: {
  //     "@type": "Product",
  //     name: `${appName}`,
  //   },
  //   author: {
  //     "@type": "Person",
  //     name: "Users",
  //   },
  //   reviewRating: {
  //     "@type": "Rating",
  //     ratingValue: "5",
  //     bestRating: "5",
  //   },
  //   reviewBody: "Our Secret Weapon!",
  // };

  // https://developers.google.com/search/docs/appearance/structured-data/search-gallery
  // https://developers.google.com/search/docs/appearance/structured-data
  // https://search.google.com/test/rich-results/result?id=m24q7un-2zRAn32NO6Ez6A - site
  // https://search.google.com/test/rich-results/result/r%2Fsitelinks-searchbox?id=lsV86ttIlM6EDEOban1m6g - code
  // https://support.google.com/webmasters/thread/214662915?hl=en&msgid=214663701
  // https://developers.google.com/search/docs/appearance/sitelinks
  const structuredData = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: `${appName}`,
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: 4.5,
        reviewCount: 197,
      },
      offers: {
        "@type": "AggregateOffer",
        offerCount: 3,
        lowPrice: 0,
        highPrice: 149.0,
        priceCurrency: "USD",
      },
      review: [
        {
          "@type": "Review",
          name: "Saved Us Countless Hours",
          reviewBody: `${appName} has been a game-changer for our organization.`,
          datePublished: "11/22/2023",
          author: { "@type": "Person", name: "Sarah R." },
          locationCreated: {
            "@type": "AdministrativeArea",
            name: "Chicago, IL",
          },
          reviewRating: { "@type": "Rating", ratingValue: 5 },
        },
        {
          "@type": "Review",
          name: "Like Having A Brilliant Co-Writer",
          reviewBody: `I&#x27;ve never seen a tool as powerful as ${appName}.`,
          datePublished: "11/15/2023",
          author: { "@type": "Person", name: "Mark S." },
          locationCreated: {
            "@type": "AdministrativeArea",
            name: "Seattle, WA",
          },
          reviewRating: { "@type": "Rating", ratingValue: 4.5 },
        },
      ],
    },
    {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      url: `${APP_URL}`,
      potentialAction: {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${APP_URL}/#{search_term_string}`,
        },
        "query-input": "required name=search_term_string",
      },
    },    
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      image: "https://www.example.com/example_image.jpg",
      url: `${APP_CONST.LANDING_URL}/about`,
      logo: `${APP_CONST.logoUrl}`,
      name: `${appName}`,
      description:
        `Watch your proposals soar to new heights as ${appName} supercharges your team's creativity, streamlines collaboration. Unleash the potential of your grant writing dreams with GrantWrite.`,
      contactPoint: {
        "@type": "ContactPoint",
        email: "support@grantwriteai.com",
        contactType: "Customer Service",
      },
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Will AI replace Grant Writers?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Grant writers & grant seekers can embrace AI as an assistant rather than a replacement. AI tools offer valuable support by automating repetitive tasks, providing data insights, highlighting compliance issues, and suggesting improvements. Grant writers can leverage this technology to streamline their workflows, improve efficiency, and focus on strategic thinking and relationship-building.",
          },
        },
        {
          "@type": "Question",
          name: "How AI is shaping the work of Professional Grant Writers?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Advanced AI tools now play a pivotal role in automating key elements of the grant writing process. From streamlining data collection and analysis to refining proposal formatting, correcting grammar, and even generating textual sections, AI's capabilities bring unprecedented efficiency, accuracy, and time-saving advantages to the craft of grant writing.",
          },
        },
        {
          "@type": "Question",
          name: "How do Funders feel about AI?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "AI raises concerns regarding biases and ethical considerations in grant proposal writing. Human grant writers are crucial in ensuring fairness, transparency, and adherence to ethical guidelines. They possess the ability to understand and address complex issues, anticipate funders’ concerns, and weave responsible storytelling incorporating the organization’s impact and values.",
          },
        },
      ],
    },
  ];

  // const renderSiteLinks = () => {
  //   return (
  //     <JSONLD>
  //       <Generic
  //         type="WebSite"
  //         schema={{
  //           "@context": "https://schema.org/",
  //           "@type": "WebSite",
  //           url: "https://www.grantwriteai.com/",
  //           potentialAction: {
  //             "@type": "SearchAction",
  //             target: {
  //               "@type": "EntryPoint",
  //               urlTemplate:
  //                 "https://www.grantwriteai.com/{search_term_string}",
  //             },
  //             "query-input": "required name=search_term_string",
  //           },
  //         }}
  //       />
  //     </JSONLD>
  //   );
  // };

  return (
    <>
      {structuredData.map((data, index) => (
        <script
          key={index}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(data),
          }}
        />
      ))}
    </>
  );
};

export default SEO;
