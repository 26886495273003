import { BsRocketTakeoff } from "react-icons/bs/index";
import editor1 from "@assets/editor-lg.png";
import editor2 from "@assets/editor-sm.png";
import gptImg from "@assets/gpt.svg";
import collabImg from "@assets/collaborate.svg";
import toolkitImg from "@assets/toolkit.svg";
import chargeIcon from "@assets/charge-icon.svg";
import checkIcon from "@assets/check-icon.svg";
import calendar from "@assets/calendar.png";
import patternImg from "@assets/bg-pattern2.svg";
import patternBg from "@assets/bg-pattern1.png";
import patternAtt1 from "@assets/pattern-attachment1.png";
// import { url } from "inspector";
import Features from "./Features";
import Pricing from "./Pricing";
import Reviews from "./Reviews";
import Deal from "./Deal";
import Faqs from "./FAQ";
import LandingVideo from "@components/Video";
import { useFlags } from "@utils/hooks";
import { appName, isDev } from "@utils/index";
import { useState } from "react";
import Recommendations from "./Recommendations";
import Hero from "./Hero";
import Logos from "./Logos";

const Landing = () => {
  const {
    data: abTestVideo,
    isLoading,
    isError,
  } = useFlags("LANDING_AB_VIDEO");

  return (
    <div className="overflow-hidden">
      <div id="home" className="-sm:p-2 sm:p-10 text-center bg-primary-light">
        <div className="mb-10">
          <p className="mb-6 pt-[72px] -sm:pt-14 text-7xl -lg:text-[64px] -md:text-[52px] -sm:text-[46px] text-center font-semibold leading-tight">
            Supercharge your <br />{" "}
            <span className="gradient-text">Grant Writing with AI</span>
          </p>
          <p className="max-w-[1000px] mx-auto mb-24 -sm:mb-16 text-4xl -lg:text-[32px] -md:text-[28px] -sm:text-2xl text-gray-600">
            Say goodbye to stress and hello to success, with a grant writing
            partner that's always a step ahead.
          </p>
          <div className="flex-center -xs:flex-col gap-6 text-[18px] -sm:text-base">
            <GetStarted mxAuto={false} />
            <button
              onClick={() => window.open("#features", "_self")}
              className=" -sm:p-2 p-3.5 px-8 flex-center-y text-primary-dark border border-primary-dark rounded-md"
            >
              How It Works
            </button>
          </div>
        </div>

        {
          // abTestVideo?.isEnabled ? (
          //   <LandingVideo />
          // ) :
          // isDev ?
          <Recommendations />
          // : <Hero />
        }
      </div>

      <Logos />

      <Features />

      <div className="-sm:px-5 sm:px-10 pt-20 pb-[2rem] bg-primary-light">
        <div className="mb-14 flex -lg:flex-col gap-14">
          <div className="w-[50%] -lg:w-full -lg:max-w-[700px] mx-auto relative flex-shrink-0">
            <img className="w-full" src={editor1} alt="" />
            <img className="w-[30%] absolute top-0" src={editor2} alt="" />
          </div>
          <div>
            <p className="mb-4 text-4xl -sm:text-[32px] font-bold">
              Our AI Writing Assistant Is Here To Help You!
            </p>
            <p className="text-xl -sm:text-lg text-[#344054]">
              Our AI writing assistant is designed to help you effortlessly
              craft compelling grant proposals for your success.
            </p>
            <div className="mt-7 -sm:text-[15px] text-[#636363] grid gap-6 -sm:gap-4">
              <div className="py-3 px-5 flex -xs:flex-col gap-4 -sm:gap-2.5 bg-white shadow-lg rounded-[10px]">
                <img className="-sm:w-14" src={gptImg} alt="" />
                <div>
                  <p className="mb-2 -sm:mb-1 text-xl -sm:text-lg text-black font-bold">
                    AI-Powered Precision
                  </p>
                  <p>
                    Our platform leverages cutting-edge artificial intelligence
                    to refine and enhance your grant writing process.
                  </p>
                </div>
              </div>
              <div className="py-3 px-5 flex -xs:flex-col gap-4 -sm:gap-2.5">
                <img className="-sm:w-14" src={collabImg} alt="" />
                <div>
                  <p className="mb-2 -sm:mb-1 text-xl -sm:text-lg text-black font-bold">
                    Collaboration Simplified
                  </p>
                  <p>
                    Work seamlessly with team members, ensuring that everyone
                    contributes to crafting successful grant proposals.
                  </p>
                </div>
              </div>
              <div className="py-3 px-5 flex -xs:flex-col gap-4 -sm:gap-2.5">
                <img className="-sm:w-14" src={toolkitImg} alt="" />
                <div>
                  <p className="mb-2 -sm:mb-1 text-xl -sm:text-lg text-black font-bold">
                    Comprehensive Toolkit
                  </p>
                  <p>
                    We offer more than just a platform; we provide a
                    comprehensive toolkit for grant success
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-16 flex -lg:flex-col -lg:items-center gap-14">
          <div className="lg:min-w-[400px] text-lg -sm:text-base text-[#667085]">
            <img
              className="-sm:w-9 mb-6 -sm:mb-4 p-2 bg-secondary-light rounded-full"
              src={chargeIcon}
              alt=""
            />
            <p className="mb-4 text-4xl -sm:text-[32px] text-black font-bold">
              Easily Manage Your Grant
            </p>
            <p className="mb-6 -sm:mb-5">
              Effortlessly and efficiently maintain control and oversight of all
              your grant-related activities using our user-friendly and
              intuitive platform.
            </p>
            <ul>
              <li className="my-5 -sm:my-3.5 flex gap-2.5 -sm:gap-2">
                <img className="-sm:w-6" src={checkIcon} alt="" />
                <span>Collaborate under one roof</span>
              </li>
              <li className="my-5 -sm:my-3.5 flex gap-2.5 -sm:gap-2">
                <img className="-sm:w-6" src={checkIcon} alt="" />
                <span>Stay on top of deadlines</span>
              </li>
              <li className="my-5 -sm:my-3.5 flex gap-2.5 -sm:gap-2">
                <img className="-sm:w-6" src={checkIcon} alt="" />
                <span>Manage all aspect of your grants.</span>
              </li>
            </ul>
          </div>
          <img
            className="w-[calc(60%-56px)] -lg:w-full -lg:max-w-[700px] h-[100%]"
            src={calendar}
            alt=""
          />
        </div>

        <GetStarted />
      </div>

      <div className="mb-10 -sm:px-5 sm:px-10 py-14">
        <div className="text-center">
          <p className="mb-10 -sm:mb-7 text-4xl -sm:text-[32px] font-bold">
            Increase your efficiency with{" "}
            <span className="gradient-text">{appName}</span>
          </p>
          <div className="mb-12 -sm:text-[15px] flex -sm:flex-col justify-center -sm:items-center gap-10">
            <div className="max-w-[300px] sm:max-w-[200px]">
              <p className="mb-2 text-5xl -sm:text-[40px] text-primary-dark font-bold">
                ^2x
              </p>
              <p className="text-[#475467]">
                With {appName}, you can increase your grant output by{" "}
                <span className="text-black font-bold">2x</span>
              </p>
            </div>
            <div className="max-w-[300px] sm:max-w-[200px]">
              <p className="mb-2 text-5xl -sm:text-[40px] text-primary-dark font-bold">
                +5 hrs
              </p>
              <p className="text-[#475467]">
                Save <span className="text-black font-bold">5 hours</span> per
                grants thanks to AI powered workflows
              </p>
            </div>
            <div className="max-w-[300px] sm:max-w-[200px]">
              <p className="mb-2 text-5xl -sm:text-[40px] text-primary-dark font-bold">
                ^80%
              </p>
              <p className="text-[#475467]">
                <span className="text-black font-bold">8 out of 10</span> users
                say they’ve developed a stronger grant strategy
              </p>
            </div>
          </div>

          <GetStarted />
        </div>

        <div className="mt-16 lg:pr-5 flex -lg:flex-col-reverse items-end relative">
          <div
            style={{ backgroundImage: `url(${patternBg})` }}
            className="w-full lg:h-[85%] pt-6 pb-8 mt-auto flex bg-primary-dark bg-cover lg:absolute z-0 rounded-xl"
          >
            <div className="lg:w-1/2 h-full pl-[12%] -lg:pr-[9%] pr-14 flex-center-x flex-col">
              <p className="text-3xl text-white font-bold">
                Ready to start that grant? <br /> Create an account for free!
              </p>
              <button
                onClick={() => window.open("#pricing", "_self")}
                className="w-max mt-10 -sm:p-2 sm:py-2.5 sm:px-5 flex-center-y text-primary-dark font-semibold bg-white rounded-lg"
              >
                Get Started
              </button>
            </div>
          </div>
          <img
            className="lg:w-1/2 -lg:w-full -sm:w-full max-w-[700px] ml-auto -lg:mr-auto z-30 flex-shrink-0"
            src={patternAtt1}
            alt=""
          />
        </div>
      </div>

      <Pricing />

      <Reviews />

      <div className="-sm:p-5 sm:px-10 py-12">
        <div
          style={{ backgroundImage: `url(${patternImg})` }}
          className="w-full bg-primary-dark bg-cover z-0 rounded-xl"
        >
          <div className="max-w-[600px] h-full py-12 bg-slate-40 pl-[12%] -lg:pr-[9%] pr-14 flex-center-x flex-col relative text-xs text-white">
            <p className="mb-6 text-[30px] leading-tight font-bold">
              Ready to start that grant! <br /> Let’s work together.
            </p>
            <p className="mb-6">
              Embark on a journey of AI-enhanced proposal crafting,
              collaborative excellence, and efficient grant tracking with our
              comprehensive suite of features designed to streamline every step
              of the process. Your successful grant journey begins with a simple
              click.
            </p>
            {/* <button
              onClick={() => window.open("#pricing", "_self")}
              className="w-content py-3 px-6 flex-center-y  rounded-md"
            >
              <span>Get Started</span>
            </button> */}
            <GetStarted
              showIcon={false}
              mxAuto={true}
              variant={true}
              className=""
            />
          </div>
        </div>
      </div>

      {/* <Deal /> */}

      <Faqs />
    </div>
  );
};

interface GSDefault {
  className?: string;
  showIcon?: boolean;
  mxAuto?: boolean;
  variant?: boolean;
}

// type GSOptional =  {
//   showIcon?: never;
//   mxAuto?: never;
//   variant?: boolean;
// } | {
//   showIcon?: boolean;
//   mxAuto?: boolean;
//   variant: boolean;
// }

// type Props = GSDefault & GSOptional;

export const GetStarted = ({
  showIcon = true,
  mxAuto = true,
  variant = false,
  className: cN,
}: GSDefault) => {
  return (
    <button
      onClick={() => window.open("#pricing", "_self")}
      className={`${mxAuto ? "mx-auto" : ""} -sm:p-3 sm:p-3.5 sm:px-8 ${
        variant
          ? "text-primary-dark bg-white"
          : " flex-center-y text-white bg-primary-dark"
      } rounded-md ${cN ? cN : ""}`}
    >
      {showIcon ? <BsRocketTakeoff /> : null}
      <span className={`${showIcon ? "ml-2" : ""}`}>Get Started</span>
    </button>
  );
};

export default Landing;
