// https://stephencook.dev/blog/using-window-in-react-ssr/
import { useColorModeValue } from "@chakra-ui/react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

import { API_URL, getWindow } from "./index.js";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { Dict, GrantRec } from "./interface.js";

//export pkg
export const useLocalStore = useLocalStorage;
export const useReadLocalStore = useReadLocalStorage;

export const fetchApi = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  options?: {
    body: string;
  },
  cb?: () => void
) => {
  // console.log("options", JSON.stringify(options));
  const response = await fetch(url, {
    method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    ...(options && options),
  });
  // .then((res) => res.json())
  // .then((data) => {
  //   return data;
  // })
  // .finally(() => {
  //   cb && cb();
  // });

  if (!response.ok) {
    throw new Error("Network response failed");
  }
  if (cb) {
    cb();
  }
  return response.json();
};

export function useQueryParams<T>(query?: string) {
  // https://www.dhiwise.com/post/unlock-the-power-of-react-router-query-params
  query = query || useReadLocalStorage<string>("query") || "";
  const searchParams = new URLSearchParams(query) as any;
  const params: Record<string, T> = {};

  // @ts-ignore
  for (const param of searchParams) {
    const isBoolType = ["true", "false"].includes(param[1]);
    params[param[0]] = isBoolType ? /true/i.test(param[1]) : param[1];
  }

  return params;
}

export const useColorMode = (light: string, dark: string) => {
  let _useColorModeValue;

  // if (!import.meta.env.SSR) {
  if (getWindow()) {
    _useColorModeValue = useColorModeValue(light, dark);
  }
  return _useColorModeValue;
};

export function useFlags(flagName: string) {
  const getFlags = async () =>
    await fetchApi(`${API_URL}/flag/${flagName}`, "GET");
  return useQuery<{ isEnabled: boolean }>({
    enabled: !!getWindow(),
    queryKey: ["flags", flagName],
    queryFn: getFlags,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
}

export function useGetRecs(payload: Dict) {
  // const { name, email, website } = payload;
  const getRecs = async () =>
    await fetchApi(`${API_URL}/landing/recs`, "POST", {
      body: JSON.stringify(payload),
    });
  return useQuery<GrantRec[]>({
    // enabled: !!getWindow(),
    enabled: false,
    queryKey: ["recs", payload],
    // queryKey: ["recs"],
    queryFn: getRecs,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });
}

export const setURLQuery = (params: Record<string, any>) => {
  // https://stackoverflow.com/a/73740327
  const currentUrlParams = new URLSearchParams(window.location.search);
  for (const key in params) {
    if (params[key] === null) {
      currentUrlParams.delete(key);
    } else {
      currentUrlParams.set(key, params[key]);
    }
  }

  const newUrl = window.location.pathname + "?" + currentUrlParams.toString();
  // console.log("newUrl", newUrl);
  window.history.replaceState(null, "", newUrl);
};

export function useMutationObserver(callback?: any) {
  // useEffect(() => {
  //   const observer = new MutationObserver((mutations) => {
  //     mutations.forEach((mutation) => {
  //       if (mutation.type === 'childList') {
  //         mutation.addedNodes.forEach((node) => {
  //           if (node.nodeName === 'IFRAME') {
  //             console.log('IFRAME found:', node);

  //             callback(node);
  //           }
  //         });
  //       }
  //     });
  //   });

  //   observer.observe(document.body, { childList: true, subtree: true });

  //   return () => observer.disconnect();
  // }, [callback]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === "IFRAME") {
              console.log("IFRAME found:", node);
              const iframe = node as HTMLIFrameElement;
              iframe?.contentDocument?.addEventListener("click", (event) => {
                console.log("Iframe content clicked:", event);
              });
            }
          });
        }
      });
    });

    // Start observing the document with the configured parameters
    observer.observe(document, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);
}
