import { ReactNode } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import { appName } from "@utils/index";

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={"h3"} textAlign={"center"} fontSize={"xl"}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string;
  name: string;
  title: string;
}) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function Testimonials() {
  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.700")}
      pt={{ base: 10 }}
      pb={{ base: 10 }}
    >
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Heading>What Others Are Saying</Heading>
          <Text>We have been working with organizations around the nation</Text>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                Like Having A Brilliant Co-Writer
              </TestimonialHeading>
              <TestimonialText>
                {`"As a grant writer, I've never seen a tool as powerful as ${appName}. It's like having a brilliant co-writer who knows exactly what funders want. Our success rate has soared since we started using it!"`}
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={"https://i.pravatar.cc/150?u=Ashton_Schimmel@yahoo.com"}
              name={"Mark S."}
              title={"Grant Writer"}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Saving Us Countless Hours</TestimonialHeading>
              <TestimonialText>
                {`"${appName} has been a game-changer for our organization. It transformed the way we approach grant writing, saving us countless hours and helping us secure funding we never thought possible."`}
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={"https://i.pravatar.cc/150?u=Florencio.Farrell6@yahoo.com"}
              name={"Sarah R."}
              title={"Nonprofit Director"}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Our Secret Weapon!</TestimonialHeading>
              <TestimonialText>
                {`"${appName} is the secret weapon every grant-seeking team needs. It's like having an entire research team and writing coach in one. Our proposals have become more competitive and compelling thanks to this incredible tool."`}
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={"https://i.pravatar.cc/150?u=Demond_Robel72@hotmail.com"}
              name={"Mason A."}
              title={"Development Manager"}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}
