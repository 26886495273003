import React, { Suspense, useContext, useEffect, useState } from "react";
// import './styles/styles.css';
// import { BrowserRouter } from 'react-router-dom';
// import 'react-toastify/dist/ReactToastify.css';
import ContextProvider from "./context";
// import {
//   // ErrorBoundaryContainer,
//   // LoaderText
// } from './ErrorBoundary';
// import Lottie from 'react-lottie';
import ErrorBoundary from "./ErrorBoundary";
import AppWrapper from "./AppWrapper";
// import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from "react-query";
import SSRTest from "./components/ssrTest/SSRTest";

const queryClient = new QueryClient();

function AppRoot() {
  // const [mounted, setMounted] = useState(false);
  // useEffect(() => {
  //    setMounted(true);
  // }, []);

  // return (<SSRTest />);
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          {/* <ToastContainer limit={1} style={{ fontSize: '16px' }} /> */}
          <AppWrapper />
        </ContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default AppRoot;
