// import { useState } from 'react'
// import reactLogo from './assets/react.svg'
// import './App.css'
import "./tailwind.css";
import {
  // BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Signup from "./components/Signup";
import LandingOld from "./components/landing/old/Landing";
import LandingOutlet from "./components/landing/old";
import Landing2Outlet from "./components/landing/new";
import LandingNew from "./components/landing/new/Landing";
import Login from "./components/Login";
import Contact from "./components/Contact";
import SSRTest from "./components/ssrTest/SSRTest";
import About from "./components/About";

import { useEffect } from "react";
// import { useLocalStorage } from 'usehooks-ts';
import { fetchApi, getWindow, baseUrl, useLocalStore, APP_URL } from "./utils";
import Redirect from "@components/Redirect";
// import Waitlist from "./components/Waitlist";

const App = () => {
  // get query params from router and store in local storage
  const [_, setQueryParams] = useLocalStore("query", "");

  // useEffect(() => {
  //   //clear local storage
  //   setQueryParams("");
  // }, []);

  useEffect(() => {
    if (getWindow()?.location.search) {
      // console.log("query", query);
      //remove to clear cache
      setQueryParams(getWindow()!.location.search);
    }
  }, [getWindow()?.location.search]);

  return (
    <div>
      <Routes>
        <Route element={<LandingOutlet />}>
          {/* 404 /catch all */}
          <Route path={`${baseUrl.landing1}/*`} element={<LandingOld />} />
          <Route path={`${baseUrl.landing1}/signup`} element={<Signup />} />
          <Route path={`${baseUrl.landing1}/about`} element={<About />} />
          <Route path={`${baseUrl.landing1}/contact`} element={<Contact />} />
        </Route>
        <Route element={<Landing2Outlet />}>
          <Route path={`${baseUrl.landing2}/*`} element={<LandingNew />} />
          {/* <Route path={`${baseUrl.landing2}/signup`} element={
            <Redirect url={`${APP_URL}/signup`} />
          // <Navigate to={`${APP_URL}/signup`} replace/>
          } /> */}
          <Route path={`${baseUrl.landing2}/about`} element={<About />} />
          <Route path={`${baseUrl.landing2}/contact`} element={<Contact />} />
        </Route>
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/waitlist" element={<Waitlist />} /> */}
        {/* <Route path="/blog" element={<Blog />}>
          <Route path="" element={<Posts />} />
          <Route path=":postSlug" element={<Post />} />
        </Route> */}
      </Routes>
    </div>
  );
};

export default App;
