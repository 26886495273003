import { appName } from "./index.js";
import { isDev, isProd } from "./helpers.js";
const slackEndpoint = process.env.SLACK_ALERTS_URL;
// const slackEndpoint = import.meta.env.SLACK_ALERTS_URL;

// console.log(`slackEndpoint: ${slackEndpoint}`);
// console.log(`isDev: ${isDev}`);

// https://stackoverflow.com/q/45752537
export const notifySlack = async (
  data: any,
  endpoint?: string,
  slackOptions: object = {}
) => {
  if (slackEndpoint) {
    const text =
      "```" +
      (typeof data === "string"
        ? data
        : `(${data?.name || "Slack"}: ${
            data?.message || "Msg"
          })\n${JSON.stringify(data)}`) +
      "```";
    fetch(endpoint || (slackEndpoint as string), {
      method: "post",
      headers: {},
      body: JSON.stringify(
        Object.assign(
          {},
          { text },
          {
            username: `${appName}-L | ${isProd ? "prod" : "dev"}`,
            ...slackOptions,
          }
        )
      ),
    }).catch((error: Error) => {
      console.log(`ERROR POSTING TO SLACK: ${error}, stack: ${error.stack}`);
    });
  }
};
