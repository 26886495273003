import feature1 from "@assets/features/data.svg";
import feature2 from "@assets/features/robot.svg";
import feature3 from "@assets/features/navigate.svg";
import feature4 from "@assets/features/people.svg";
import feature5 from "@assets/features/document.svg";
import feature6 from "@assets/features/library.svg";
import { appName } from "@utils/index";

const Features = () => {
  const features = [
    {
      name: `Grant Database`,
      desc: `Access a comprehensive grant database curated by AI, offering a vast collection of funding opportunities`,
      icon: feature1,
    },
    {
      name: `AI Enhanced`,
      desc: `Receive real-time suggestions to improve clarity, coherence, and alignment with grant criteria, increasing your chances of approval.`,
      icon: feature2,
    },
    {
      name: `Grant Tracking`,
      desc: `Track the status of your applications, set reminders for deadlines, and receive updates on grant progress, streamlining your grant management process.`,
      icon: feature3,
    },
    {
      name: `Collaborative Workflow`,
      desc: `Assign reviewers, set deadlines, and track feedback within the platform, ensuring comprehensive input from all team members.`,
      icon: feature4,
    },
    {
      name: `Secure Document Storage`,
      desc: `Maintain a centralized repository within the platform for all grant-related documents, ensuring easy access, version control, and enhanced collaboration.`,
      icon: feature5,
    },
    {
      name: `Snippets Library`,
      desc: `Utilize our snippets library to recycle existing responses,stores responses for future use and streamline proposal drafting.`,
      icon: feature6,
    },
  ];

  return (
    <div id="features" className="p-10 -sm:text-[15px] text-center">
      <h2 className="mb-3 text-5xl -sm:text-[40px] font-bold">Features</h2>
      <p>
        {`Few good reasons why you should use ${appName} for your grant writing`}
      </p>
      <div className="mt-8 grid grid-cols-3 -lg:grid-cols-2 -sm:grid-cols-1 place-items-center gap-8">
        {features.map((feature) => {
          return (
            <div
              className="max-w-[400px] h-full px-4 py-8 flex-center-y flex-col shadow-xl shadow-[rgba(51,102,255,0.05)] rounded-[20px]"
              key={feature.name}
            >
              <img
                className="-md:w-[70px]"
                src={feature.icon}
                alt={feature.name}
              />
              <p className="my-5 -sm:mb-3.5 text-2xl -sm:text-[22px] font-bold">
                {feature.name}
              </p>
              <p>{feature.desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
