// https://www.reddit.com/r/reactjs/comments/x0uetq/how_do_i_add_a_stripe_pricing_table_to_my_react/
import React, { useCallback, useEffect, useRef } from "react";
import {
  getDocument,
  isDev,
  notifySlack,
  useMutationObserver,
  useReadLocalStore,
} from "@utils/index";

// https://stackoverflow.com/questions/2381336/detect-click-into-iframe-using-javascript

// Given the constraints of the Same-Origin Policy, directly listening for button clicks inside an iframe from a different domain is not possible due to security reasons.
export const StripePricingTable = () => {
  const query = useReadLocalStore<string>("query");
  const scriptRef = useRef<any>(null);
  let interval;

  const handleMessage = (event) => {
    // if (event.origin !== 'https://js.stripe.com') return;
    // console.log('Message received', event);

    if (event.data.type === "button-click") {
      console.log("Button in iframe was clicked");
      notifySlack({ query }, process.env.SLACK_ACTIVITY_URL);
      event.preventDefault();
    }
  };

  const handleAlert = () => {
    interval = setTimeout(() => {
      if (document?.activeElement?.tagName === "IFRAME") {
        notifySlack({ query }, process.env.SLACK_ACTIVITY_URL);
      }
    }, 0);
  };

  const pricingTableId = isDev
    ? "prctbl_1OJ5CvKsI5iLZjovdVdnq0jz"
    : "prctbl_1OJ59uKsI5iLZjovCHoNi7PR";

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    script.onload = () => {
      const pricingTable = document.querySelector(
        `stripe-pricing-table[pricing-table-id="${pricingTableId}"]`
      );
      if (pricingTable) {
        // console.log('pricingTable loaded', pricingTable);
        const shadowRoot = pricingTable.shadowRoot;

        // Assuming the script creates an iframe with a specific ID or class
        const iframe1 = shadowRoot?.querySelector("iframe"); // Adjust the selector as needed

        if (iframe1) {
          iframe1.onload = () => {
            // console.log('Iframe1 loaded', iframe1);
            // Perform any actions needed after the iframe loads
            // iframe1.contentWindow.postMessage({ action: 'addEventListeners' }, 'https://js.stripe.com');
            // document.addEventListener('blur', function () {
            //   console.log('iframe1 Button focus');
            //   window.parent.postMessage({ type: 'button-focus', message: 'Button clicked' }, 'https://js.stripe.com');
            // }, true);
            // iframe1.contentWindow.addEventListener('message', handleMessage, false);
            // Ensure iframe1 is loaded before trying to access its content
            // Access the content document of iframe1
            // console.log('iframeContent', iframe1?.contentDocument);
            // const iframeContent = iframe1.contentWindow.document;
            // // Find the button inside iframe1 and add a click event listener
            // const button = iframeContent.querySelector('.PriceColumn-button'); // Adjust the selector as needed
            // button.addEventListener('click', () => {
            //   console.log('Button inside iframe1 was clicked');
            //   window.parent.postMessage({ type: 'button-click', message: 'Button clicked in iframe1' },
            //     // '*'
            //     'https://js.stripe.com'
            //   );
            //   notifySlack({ query }, process.env.SLACK_ACTIVITY_URL);
            // });
            // const iframe2 = iframe1?.contentDocument?.querySelector('iframe'); // Adjust the selector as needed
            // if (iframe2) {
            //   iframe2.onload = () => {
            //   console.log('Iframe2 loaded');
            //   // Add an event listener for clicks inside iframe2
            //   iframe2.contentDocument.addEventListener('click', function (event) {
            //     // Use postMessage to communicate the click event to the parent document
            //     window.parent.postMessage({ type: 'button-click', message: 'Button clicked in iframe2' }, '*');
            //   });
            //   };
            // }
          };
        }
      }
    };
    scriptRef.current = script;
    document.body.appendChild(script);

    // // Add the event listener
    window.addEventListener("message", handleMessage);

    window.focus();
    window.addEventListener("blur", handleAlert, { once: true });

    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("blur", handleAlert);
      clearTimeout(interval);
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement(
    "stripe-pricing-table",
    isDev
      ? {
          "pricing-table-id": pricingTableId,
          "publishable-key":
            "pk_test_51OJ4rDKsI5iLZjov2bIx5RQ7B4bJwngEk9tGnKkwCNVqS93GHoiJIrClUW1eSCbK9Ge9TVhkMSGUdbpzcHO0pBp800aOM74kkr",
        }
      : {
          "pricing-table-id": pricingTableId,
          "publishable-key":
            "pk_live_51OJ4rDKsI5iLZjov75Q2XERoQ1r6bUj9Q1uSePh79cDnzg1P0yg3xzhUKGkxbaktonL4xCNOTVEkrJwUGwuxY1tS00DUv0WUO6",
          // "customer-email": "",
        }
  );
};

{
  /* <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
<stripe-pricing-table pricing-table-id="prctbl_1NMFxgEdMGilw8qrq8MHdeFM"
publishable-key="pk_test_593zbYRN9w6DgS9hKAxZQFaY">
</stripe-pricing-table> */
}
