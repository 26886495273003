import logo from "@assets/granwrite-logo.svg";
import { BsYoutube, BsTwitter } from "react-icons/bs/index";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa/index";
import { appName, baseUrl } from "@utils/index";

function Footer() {
  return (
    <div className=" py-6 bg-primary-light">
      <div className="-sm:px-2 sm:px-14 pb-16 border-b border-gray-300 flex -md:flex-col gap-y-10 justify-between items-start">
        <div className="w-[30%] flex-shrink-0">
          <a href={`${baseUrl.landing2}/`}>
            <img className="w-[50%] min-w-[100px]" src={logo} alt="logo" />
          </a>
        </div>
        <div className="w-full flex -sm:flex-col justify-between gap-10">
          <div className="flex flex-col gap-2.5">
            <p className="mb-1 font-bold">Contact Us</p>
            <a href={`${baseUrl.landing2}/contact`}>Contact Support</a>
            <a target="blank" href={`https://linktr.ee/grantwriteai?subscribe`}>
              Linktree
            </a>
          </div>
          <div className="flex flex-col gap-2.5">
            <p className="mb-1 font-bold">Information</p>
            <a href={`${baseUrl.landing2}/#features`}>Features</a>
            <a href={`${baseUrl.landing2}/#pricing`}>Pricing</a>
            <a href={`${baseUrl.landing2}/#faq`}>FAQ</a>
            <a target="blank" href={`//blog.grantwriteai.com`}>
              Blog
            </a>
            <a href={`${baseUrl.landing2}/contact`}>Help</a>
          </div>
          <div className="flex flex-col gap-2.5">
            <p className="mb-1 font-bold">Company</p>
            <a href={`${baseUrl.landing2}/about`}>About us</a>
            <a target="blank" href={`//blog.grantwriteai.com/affiliate`}>
              Affiliate Program
            </a>
            <a href={`${baseUrl.landing2}/#pricing`}>Register</a>
            <a target="blank" href={`//app.grantwriteai.com/login`}>
              Login
            </a>
            <a href={`${baseUrl.landing2}/`}>{appName}</a>
          </div>
        </div>
      </div>
      <div className="pt-5 -lg:px-14 grid grid-cols-3 -lg:grid-cols-2 -sm:grid-cols-1 lg:place-items-center gap-y-4">
        <div className="-lg:hidden"></div>
        <div className="flex items-center -sm:justify-center -sm:flex-wrap gap-14 text-sm">
          <a
            target="blank"
            href={`https://docs.google.com/document/d/1x2-dGhYD_Ru_Vkix0M7kUtE2VlZ7_zmRXdqFqda9omU/pub`}
          >
            Terms
          </a>
          <a
            target="blank"
            href={`https://docs.google.com/document/d/1gSj7UQAu-skkm6kzc1KhFCAKXvtBWHx-1fBdjzOlT-c/pub`}
          >
            Privacy
          </a>
          <a
            target="blank"
            href={`https://docs.google.com/document/d/1RMax2p3vmZOeSKG6ije7c4xu50tVznwtNLMZWsi4Qn4/pub`}
          >
            Cookies
          </a>
        </div>
        <div className="flex -lg:justify-end -sm:flex-wrap -sm:gap-10 -sm:justify-center gap-5 text-sm">
          <div className="w-9 h-9 flex-center xs:border border-gray-300 rounded-full">
            <a target="blank" href={`//linkedin.com/company/grantwriteai`}>
              <FaLinkedinIn />
            </a>
          </div>
          <div className="w-9 h-9 flex-center xs:border border-gray-300 rounded-full">
            <a target="blank" href={`//facebook.com/grantwriteai`}>
              <FaFacebookF />
            </a>
          </div>
          <div className="w-9 h-9 flex-center xs:border border-gray-300 rounded-full">
            <a target="blank" href={`//youtube.com/@grantwriteai`}>
              <BsYoutube />
            </a>
          </div>
          <div className="w-9 h-9 flex-center xs:border border-gray-300 rounded-full">
            <a target="blank" href={`//twitter.com/grantwriteai`}>
              <BsTwitter />
            </a>
          </div>
          <div className="w-9 h-9 flex-center xs:border border-gray-300 rounded-full">
            <a target="blank" href={`//www.instagram.com/grantwriteai`}>
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
