// https://chakra-ui.com/getting-started
import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";

function AppWrapper() {
  // return (<App />);
  return (
    <ChakraProvider>
      <App />
    </ChakraProvider>
  );
}

export default AppWrapper;
