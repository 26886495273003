import toggleIcon from "@assets/toggle-icon.svg";
import darkCheckIcon from "@assets/dark-checkmark-icon.svg";
import lightCheckIcon from "@assets/light-checkmark-icon.svg";
import { APP_CONST, APP_URL, baseUrl } from "@utils/index";
// import { Tooltip } from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa/index";
import { MobileTooltip } from "@components/MobileTooltip";
import { useDisclosure } from "@chakra-ui/react";
import OverlayModal from "@components/overlay/OverlayModal";
import { useState } from "react";

const Pricing = () => {
  const [showNotice, setShowNotice] = useState(false);

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onToggle: onToggle1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onToggle: onToggle2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onToggle: onToggle3,
    onClose: onClose3,
  } = useDisclosure();
  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onToggle: onToggle4,
    onClose: onClose4,
  } = useDisclosure();
  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onToggle: onToggle5,
    onClose: onClose5,
  } = useDisclosure();
  const {
    isOpen: isOpen6,
    onOpen: onOpen6,
    onToggle: onToggle6,
    onClose: onClose6,
  } = useDisclosure();

  return (
    <>
      {showNotice ? (
        <OverlayModal
          header={"Announcement 📢"}
          title={"We're oversubscribed 🎊"}
          onClose={() => setShowNotice(false)}
        >
          <div className="flex flex-col items-center">
            <p className="text-center">
              We're currently oversubscribed. We'll notify you when we're ready
              to onboard more users.
            </p>
            <button
              onClick={() => {
                window.open(
                  "https://dashboard.mailerlite.com/forms/912629/119649669410194490/share",
                  "_blank"
                );
                setShowNotice(false);
              }}
              className="mt-5 bg-primary-dark text-white px-6 py-2 rounded-lg"
            >
              Join The Newsletter To Get Notified
            </button>
          </div>
        </OverlayModal>
      ) : null}

      <div
        id="pricing"
        className="-sm:px-5 mb-14 sm:px-10 flex-center-y flex-col text-gray-900"
      >
        <h2 className="mb-5 -sm:mb-3 text-5xl -sm:text-[40px] text-[#322659] font-bold">
          Our Pricing
        </h2>
        <p className="mb-10 -sm:mb-8 text-center">
          We have customized plans that fits your needs. <b>Start Free</b> and
          cancel anytime you want.
        </p>

        <div className="mb-14 -sm:mb-12 flex gap-3">
          <img src={toggleIcon} alt="" />
          <p>
            Toggle annual pricing for{" "}
            <span className="text-primary-dark">$$$ savings</span>
          </p>
        </div>

        <div className="mb-6 flex sm:flex-row -lg:flex-wrap justify-center gap-5">
          <div className="p-5 sm:w-[336px] -sm:w-[336px] -sm:text-[15px] bg-white rounded-[14px] shadow-xl">
            <h3 className="text-2xl -sm:text-[22px] font-bold">Basic</h3>
            <p className="mt-4 -sm:mt-3 mb-8 -sm:mb-6">
              Starter
              {/* Best for organizations that manages under 20 grants application per
            year */}
            </p>
            <div>
              <span className="text-6xl -sm:text-[52px] font-bold">$10</span>
              <span>/month</span>
            </div>
            <button
              onClick={
                () => setShowNotice(true)
                // window.open("https://buy.stripe.com/dR6cMQeqJ9FdaaYeUU", "_blank")
                // window.open(`${APP_URL}/signup`, "_self")
              }
              className="w-full my-10 -sm:my-8 p-3.5 -sm:p-3 flex-center-y text-[18px] -sm:text-base flex-center-x text-white font-bold bg-primary-dark rounded-md"
            >
              Start for free
            </button>
            <span className="text-xs font-bold italic">
              Built For Individual NPOs/SMBs
            </span>
            <ul className="text-sm text-left font-medium">
              {/* <PricingItem title="To Get You Started" variant="dark" /> */}
              <PricingItem title="1 User" variant="dark" />
              <PricingItem title="Unlimited Documents" variant="dark" />
              <PricingItem title="10 File Uploads / month" variant="dark" />
              <PricingItem title="10 AI Invocations / month" variant="dark" />
              <PricingItem
                title="Export Documents To PDF Only"
                variant="dark"
              />
              {/* <PricingItem title="Add Grants Via Form Only" variant="dark" /> */}
              {/* <PricingItem title="Basic chat and email support" variant="dark" /> */}
              <PricingItem
                title="Inactive documents deleted after 60 days"
                variant="dark"
              />
              <MobileTooltip
                label={`Need Private Secure AI? Check out our Pro Plan. Available to paid users only.`}
                isOpen={isOpen5}
              >
                <PricingItem
                  title="Access to Public AI (GPT) Only"
                  variant="dark"
                >
                  <FaInfoCircle
                    onMouseEnter={onOpen5}
                    onMouseLeave={onClose5}
                    onClick={onToggle5}
                    className={"ml-1"}
                  />
                </PricingItem>
              </MobileTooltip>
            </ul>
          </div>
          <div className="p-5 sm:w-[336px] -sm:w-[336px] -sm:text-[15px] bg-primary-dark text-white rounded-[14px] shadow-xl">
            <h3 className="text-2xl -sm:text-[22px] font-bold">Pro</h3>
            <p className="mt-4 -sm:mt-3 mb-8 -sm:mb-6">
              Most Popular
              {/* Best for organizations that manages 20-40 grants application per
            year */}
            </p>
            <div>
              <span className="text-6xl -sm:text-[52px] font-bold">$20</span>
              <span>/month</span>
            </div>
            <button
              onClick={
                () => setShowNotice(true)
                // window.open("https://buy.stripe.com/fZe4gkfuN7x5aaY9AB", "_blank")
              }
              className="w-full my-10 -sm:my-8 p-3.5 -sm:p-3 flex-center-y text-[18px] -sm:text-base flex-center-x text-primary-dark font-bold bg-white rounded-md"
            >
              Start Now
            </button>
            <span className="text-xs font-bold italic">
              Built For Individual NPOs/SMBs
            </span>
            <ul className="text-sm text-left font-medium">
              {/* <PricingItem title="Everything in the Starter Plan" variant="light" /> */}
              <PricingItem title="Unlimited Users" variant="light" />
              <PricingItem title="Unlimited Documents" variant="light" />
              <PricingItem title="Unlimited Files" variant="light" />
              <PricingItem title="Unlimited AI Invocations" variant="light" />
              <PricingItem
                title="Export Documents To PDF & DOCX"
                variant="light"
              />
              {/* <PricingItem title="Add Grants Via URL" variant="light" /> */}
              <PricingItem
                title="Standard chat and email support"
                variant="light"
              />
              <MobileTooltip
                label={`${APP_CONST.privateAIMsg}`}
                isOpen={isOpen1}
              >
                <PricingItem title="Access to Private AI" variant="dark">
                  <FaInfoCircle
                    onMouseEnter={onOpen1}
                    onMouseLeave={onClose1}
                    onClick={onToggle1}
                    className={"ml-1"}
                  />
                </PricingItem>
              </MobileTooltip>
            </ul>
          </div>

          <div className="p-5 sm:w-[336px] -sm:w-[336px] -sm:text-[15px] bg-white rounded-[14px] shadow-xl">
            <MobileTooltip
              label={`This plan is built for organizations that need to manage multiple client accounts. (e.g Agency, Consultant, Freelancer etc)`}
              isOpen={isOpen2}
            >
              <h3 className="text-2xl -sm:text-[22px] font-bold flex">
                Agency{" "}
                <FaInfoCircle
                  onMouseEnter={onOpen2}
                  onMouseLeave={onClose2}
                  onClick={onToggle2}
                  className={"ml-2"}
                />
              </h3>
            </MobileTooltip>
            <p className="mt-4 -sm:mt-3 mb-8 -sm:mb-6">
              For Agencies, Consultants, Freelancers
              {/* Freelancer */}
              <br />
              {/* Best for organizations that manages 50+ grants application per year */}
            </p>
            <div>
              <span className="text-6xl -sm:text-[52px] font-bold">$40</span>
              <span>/month</span>
            </div>
            <button
              onClick={
                () => setShowNotice(true)
                // window.open("https://buy.stripe.com/aEU7swgyR7x55UI146", "_blank")
                // window.open(`${APP_URL}/signup`, "_self")
              }
              className="w-full my-10 -sm:my-8 p-3.5 -sm:p-3 flex-center-y text-[18px] -sm:text-base flex-center-x text-white font-bold bg-primary-dark rounded-md"
            >
              Start for free
            </button>
            <span className="text-xs font-bold italic">
              For managing multiple client accounts
            </span>
            <ul className="text-sm text-left font-medium">
              <PricingItem title="Everything in the Pro Plan*" variant="dark" />
              <MobileTooltip
                label={`Manage unlimited clients and their grants for only $40`}
                isOpen={isOpen3}
              >
                <PricingItem title="Unlimited client accounts" variant="dark">
                  <FaInfoCircle
                    onMouseEnter={onOpen3}
                    onMouseLeave={onClose3}
                    onClick={onToggle3}
                    className={"ml-1"}
                  />
                </PricingItem>
              </MobileTooltip>
              <PricingItem
                title="Easily toggle between client accounts"
                variant="dark"
              />
              <PricingItem title="Unlimited AI Invocations" variant="dark" />
              {/* <PricingItem title="Share Grants Via Link" variant="dark" />
              <PricingItem title="Share Proposal Via Link" variant="dark" />
              <PricingItem title="Import Grants Via Search" variant="dark" /> */}
              <MobileTooltip
                label={`Available to paid users only.`}
                isOpen={isOpen6}
              >
                <PricingItem
                  title="Priority chat and email support"
                  variant="dark"
                >
                  <FaInfoCircle
                    onMouseEnter={onOpen6}
                    onMouseLeave={onClose6}
                    onClick={onToggle6}
                    className={"ml-1"}
                  />
                </PricingItem>
              </MobileTooltip>
              <MobileTooltip
                label={`${APP_CONST.privateAIMsg} Available to paid users only.`}
                isOpen={isOpen4}
              >
                <PricingItem title="Access to Private AI" variant="dark">
                  <FaInfoCircle
                    onMouseEnter={onOpen4}
                    onMouseLeave={onClose4}
                    onClick={onToggle4}
                    className={"ml-1"}
                  />
                </PricingItem>
              </MobileTooltip>
            </ul>
          </div>
        </div>

        {/* <div className="p-8 -sm:p-5 -lg:w-[336px] lg:w-[1048px] bg-white flex -lg:flex-col gap-x-16 gap-y-8 -sm:gap-y-6 rounded-[14px] shadow-xl">
        <div className="lg:w-[45%] flex-shrink-0">
          <h3 className="mb-6 -sm:mb-4 text-4xl -sm:text-[34px] font-bold">
            Agency Pricing
          </h3>
          <p className="mb-6 text-[18px] -sm:text-base text-gray-500">
            This plan is custom built{" "}
            <b>
              for organizations that need to manage multiple client accounts
            </b>
            . Contact us if you qualify as one of the following
          </p>
          <button
            onClick={() => window.open(`${baseUrl.landing2}/contact`, "_self")}
            className="py-3 -sm:py-2.5 px-12 -sm:px-8 flex-center-y -sm:text-sm text-white bg-primary-dark rounded-md"
          >
            Contact Us
          </button>
        </div>
        <ul className=" w-full grid sm:grid-cols-2 gap-x-3 text-xl -sm:text-lg">
          <PricingItem title="Agency" variant="dark" />
          <PricingItem title="Consultant" variant="dark" />
          <PricingItem title="Freelancer" variant="dark" />
        </ul>
      </div> */}
      </div>
    </>
  );
};

const PricingItem = ({
  title,
  variant,
  children,
}: {
  title: string;
  variant: "light" | "dark";
  children?: React.ReactNode;
}) => {
  return (
    <li className="h-max my-5 -sm:my-3.5 flex gap-2.5 -sm:gap-2">
      <img
        className="w-5 -sm:w-4 h-5 -sm:h-4 mt-1 -sm:mt-1.5"
        src={variant == "light" ? lightCheckIcon : darkCheckIcon}
        alt={title}
      />
      <span className="pt-1">{title}</span>
      {children ? children : null}
    </li>
  );
};

export default Pricing;
