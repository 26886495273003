import {
  appName,
  getWindow,
  primaryColor,
  baseUrl,
  APP_CONST,
} from "@utils/index";
import logo from "@assets/header-logo.svg";
import { MdBolt } from "react-icons/md/index";

export default function Navbar() {
  return (
    <div className="h-[75px] px-12 -lg:px-9 -md:px-6 -sm:px-5 sticky top-0 z-50 flex-center-y justify-between bg-primary-light">
      <div>
        <a href={`${baseUrl.landing2}/`}>
          <img className="h-10" src={logo} alt="logo" />
        </a>
      </div>

      <div className="flex-center-y gap-[72px]">
        <ul className="-md:hidden flex gap-10">
          <li className="-xl:hidden">
            <a href={`${baseUrl.landing2}/`}>Home</a>
          </li>
          <li className="-lg:hidden">
            <a href={`${baseUrl.landing2}/#features`}>Features</a>
          </li>
          <li className="-lg:hidden">
            <a href={`${baseUrl.landing2}/#pricing`}>Pricing</a>
          </li>
          <li className="-xl:hidden">
            {" "}
            <a target="blank" href={"//blog.grantwriteai.com"}>
              Blog
            </a>
          </li>
          <li className="-xl:hidden">
            {" "}
            <a href={`${baseUrl.landing2}/about`}>About</a>
          </li>
          <li>
            {" "}
            <a
              onClick={() =>
                window.open("//app.grantwriteai.com/login", "_self")
              }
              className="cursor-pointer border-[1px] border-primary-dark bg-primary-light text-primary-dark hover:border-0 hover:bg-secondary-light px-4 py-2 rounded-lg"
            >
              Log In
            </a>
          </li>
        </ul>

        <div className="flex gap-5">
          {/* <button
            onClick={() => window.open("//app.grantwriteai.com/login", "_self")}
            className="-sm:hidden py-3 px-8 text-primary-dark bg-secondary-light rounded-lg"
          >
            <span>Log In</span>
          </button> */}
          <button
            // onClick={() => window.open("/signup", "_self")}
            onClick={() => window.open(APP_CONST.calendlyLink, "_blank")}
            className="-sm:hidden py-3 px-8 text-white bg-primary-dark rounded-lg"
          >
            <span>Get a Demo</span>
          </button>
          <a
            href={`${baseUrl.landing2}/#pricing`}
            className="flex -xs:hidden justify-center text-sm -sm:p-4 sm:text-md sm:py-3 sm:px-8 text-primary-dark bg-secondary-light rounded-lg"
          >
            <MdBolt size={20} className="sm:mt-[.1rem]" />
            <span>Try it free</span>
          </a>
        </div>
      </div>
    </div>
  );
}
