import { useState } from "react";

const Faqs = () => {
  const faqs = [
    {
      text: "How AI is Shaping the Work of Professional Grant Writers.",
      details:
        "Advanced AI tools now play a pivotal role in automating key elements of the grant writing process. From streamlining data collection and analysis to refining proposal formatting, correcting grammar, and even generating textual sections, AI's capabilities bring unprecedented efficiency, accuracy, and time-saving advantages to the craft of grant writing.",
    },
    {
      text: "Embracing AI as a Collaborative Partner, Not a Substitute",
      details:
        "Grant writers can embrace AI as an assistant rather than a replacement. AI tools offer valuable support by automating repetitive tasks, providing data insights, highlighting compliance issues, and suggesting improvements. Grant writers can leverage this technology to streamline their workflows, improve efficiency, and focus on strategic thinking and relationship-building.",
    },
    {
      text: "Advantages of Human Ingenuity",
      details:
        "While AI can automate certain tasks, it cannot replicate the unique perspectives, critical thinking, and creativity that human grant writers bring to the table. Professionals in this field possess a deep understanding of grant opportunities, funder preferences, and effective storytelling techniques. They can craft tailored narratives that resonate with funders, pushing the boundaries to present innovative solutions.",
    },
    {
      text: "Unlocking the Potential of Collaboration",
      details:
        "The coexistence of AI technology and human expertise can lead to powerful outcomes. Grant writers can harness AI’s abilities to access extensive databases, analyze large volumes of data, and uncover hidden patterns. This collaboration empowers writers to make data-driven decisions, identify relevant grant opportunities, and enhance their proposals. Ultimately, the human touch is necessary to synthesize insights, understand the nuances, and build compelling grant narratives that establish an emotional connection with funders.",
    },
    {
      text: "Ensuring Ethical Practices and Accountability",
      details:
        "AI raises concerns regarding biases and ethical considerations in grant proposal writing. Human grant writers are crucial in ensuring fairness, transparency, and adherence to ethical guidelines. They possess the ability to understand and address complex issues, anticipate funders’ concerns, and weave responsible storytelling incorporating the organization’s impact and values.",
    },
    {
      text: "Adaptation and Lifelong Learning",
      details:
        "As AI technology evolves, grant writers must adapt and acquire new skills to remain relevant in the field. Familiarity with AI tools and staying updated on emerging trends allows professionals to leverage their expertise in combination with AI’s capabilities. This adaptability ensures that grant writers remain essential contributors in the grant writing process, embracing technology as a tool for augmentation rather than replacement.",
    },
  ];

  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      id="faq"
      className="py-10 sm:px-20 -xl:px-24 -lg:px-16 -md:px-12 -sm:px-2"
    >
      <p className="text-[18px] -sm:text-base text-[#3056D3] text-center uppercase">
        Faq
      </p>
      <p className="mb-9 text-[40px] -sm:text-4xl -sm:leading-snug text-center font-bold">
        Will AI replace Grant Writers?
      </p>

      <div className="flex flex-col gap-5">
        {faqs?.map((faq, index) => {
          return (
            <div
              onClick={() => handleToggle(index)}
              className={`sm:px-8 -sm:px-2 py-5 flex-center-y justify-between gap-3 cursor-pointer rounded-[18px] shadow-md shadow-[rgba(8,15,52,0.06)] ${
                openIndex === index && "border-2 border-primary-dark"
              }`}
              key={index}
            >
              <div>
                <p className="text-[22px] -sm:text-[20px] text-[#170F49]">
                  {faq.text}
                </p>
                <p
                  className={`mt-4 -sm:text-sm ${
                    openIndex !== index && "hidden"
                  }`}
                >
                  {faq.details}
                </p>
              </div>
              <svg
                className={`w-7 -sm:w-6 h-7 -sm:h-6 -sm:mt-4 ${
                  openIndex === index && "bg-primary-dark text-white rotate-90"
                } flex-shrink-0 rounded-full shadow-lg`}
                width="31"
                height="32"
                viewBox="0 0 31 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="chevron-right">
                  <path
                    id="Icon"
                    d="M11.5234 23.5148L19.1031 15.9352L11.5234 8.35547"
                    style={{
                      stroke: "currentColor",
                      strokeWidth: "2",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      fill: "none",
                    }}
                  />
                </g>
              </svg>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
