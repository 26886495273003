export const getWindow = () => {
  if (typeof window !== "undefined") {
    return window;
  }
  return null;
};

export const getDocument = () => {
  if (typeof document !== "undefined") {
    return document;
  }
  return null;
};

export const includesString = (arr: string[], str: string) =>
  arr.some((s) => str.includes(s));

export const isProd =
  getWindow() && getWindow()?.location.href.includes(".com");
export const isDev =
  (getWindow() && getWindow()?.location.href.includes("localhost")) ||
  getWindow()?.location.href.includes("develop.");

export const ellipsis = (str: string, len = 20) => {
  if (!len) return str;
  if (str?.length > len) {
    return str.substring(0, len) + "...";
  } else {
    return str;
  }
};

export const getOriginUrl = (url: string) => {
  // https://stackoverflow.com/a/15979390
  try {
    const link = new URL(url);
    // return `${link.protocol}//${link.host}`;
    return link?.origin;
  } catch (error) {
    console.error("Invalid URL:", error);
    return "#";
  }
};
