import { BiX } from "react-icons/bi/index";

interface CloseButtonProps {
  closeModal: () => void;
  variant?: "outline" | "full";
  className?: string;
}

const CloseBtn = ({
  closeModal,
  variant = "full",
  className = "",
}: CloseButtonProps) => {
  return (
    <button
      onClick={closeModal}
      className={
        (variant !== "full"
          ? "p-1 text-2xl rounded-full hover:bg-white hover:text-[#B784F4] transition-colors duration-150 border-2"
          : "p-2 text-3xl bg-[#eee8f8] text-[#6a54b1] hover:bg-[#6a54b1] hover:text-white rounded-full") +
        " " +
        className
      }
    >
      <BiX />
    </button>
  );
};

export default CloseBtn;
