import React, { useState, createContext } from "react";
// import { useLocation } from 'react-router-dom';

export const ContextStore = createContext({} as ContextStoreTypes);

interface FormDataProps {
  children: React.ReactNode;
}

type DefaultSetFunc = (...args: any) => void;

export interface ContextStoreTypes {}

const ContextProvider = ({ children }: FormDataProps) => {
  const values = {};

  return (
    <ContextStore.Provider value={values}>{children}</ContextStore.Provider>
  );
};

export default ContextProvider;
