import React from "react";
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from "@chakra-ui/react";
import { primaryColor } from "@utils/index";

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps["marginTop"];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={"md"} variant="solid" colorScheme={primaryColor} key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const Article = (props: {
  flip?: boolean;
  text: string;
  img: string;
  alt: string;
}) => {
  return (
    <Container maxW={"7xl"} p="12">
      {/* <Link
        href={
          "https://support.redfin.com/hc/en-us/articles/360021023832-Delisted-Properties"
        }
        target="_blank"
        textDecoration="none"
        _hover={{ textDecoration: "none" }}
      >
        <Heading textAlign={"center"} as="h1">
          Innovative Features
        </Heading>
      </Link> */}

      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        {props?.flip ? <ArticleText {...props} /> : <ArticleImage {...props} />}
        {props?.flip ? <ArticleImage {...props} /> : <ArticleText {...props} />}
      </Box>
    </Container>
  );
};

const ArticleImage = (props: { img: string; alt: string }) => (
  <Box
    display="flex"
    flex="1"
    marginRight="3"
    position="relative"
    alignItems="center"
  >
    <Box
      width={{ base: "100%", sm: "85%" }}
      zIndex="2"
      marginLeft={{ base: "0", sm: "5%" }}
      marginTop="5%"
    >
      <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
        <Image
          borderRadius="lg"
          // width={{ base: "100%" }}
          src={props?.img}
          alt={props?.alt}
          objectFit="contain"
        />
      </Link>
    </Box>
    <Box zIndex="1" width="100%" position="absolute" height="100%">
      <Box
        bgGradient={useColorModeValue(
          `radial(${primaryColor}.600 1px, transparent 1px)`,
          `radial(${primaryColor}.300 1px, transparent 1px)`
        )}
        backgroundSize="20px 20px"
        opacity="0.4"
        height="100%"
      />
    </Box>
  </Box>
);

const ArticleText = (props: { text: string }) => (
  <Box
    display="flex"
    flex="1"
    flexDirection="column"
    justifyContent="center"
    marginTop={{ base: "3", sm: "0" }}
  >
    <Text
      as="p"
      marginTop="2"
      color={useColorModeValue("gray.700", "gray.200")}
      fontSize="lg"
    >
      {props?.text}
    </Text>
  </Box>
);

export default Article;
