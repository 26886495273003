import logo1 from "@assets/logos/Aloha-Harvest-Logo.svg";
import logo3 from "@assets/logos/logo3.svg";
import logo4 from "@assets/logos/logo4.svg";
import logo5 from "@assets/logos/logo5.svg";
import logo6 from "@assets/logos/logo6.svg";
import logo8 from "@assets/logos/logo8.svg";
import logo10 from "@assets/logos/logo10.svg";
import "./scroll.css";

const Logos = () => {
  const logos = [
    {
      name: "Aloha Harvest",
      img: logo1,
    },
    {
      name: "HealthWell Foundation",
      img: logo3,
    },
    {
      name: "Sixteen Thirty Fund",
      img: logo4,
    },
    {
      name: "Bill & Melinda Gates Foundation",
      img: logo5,
    },
    {
      name: "Bloomberg Philanthropies",
      img: logo6,
    },
    {
      name: "Nonprofit",
      img: logo8,
    },
    {
      name: "Walton Family Foundation",
      img: logo10,
    },
  ];

  // return (
  //   <div
  //     className="h-40 px-10 py-10 mb-10 flex flex-col children:text-center"
  //   >
  //     <h1 className="text-2xl mb-10">Apply for grants from Foundations like</h1>
  //     <div className="justify-center flex-center-y gap-5 mx-auto">
  //       {logos?.map((logo, index) => (
  //         <div key={index}>
  //           <img src={logo?.img} alt={logo?.name} className="w-full h-auto" />
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );

  // https://freedium.cfd/https://betterprogramming.pub/how-to-create-react-infinite-slider-22b76cbd7a9
  return (
    <div className="h-40 px-10 py-10 mb-10 flex flex-col children:text-center">
      <h1 className="text-2xl mb-5">Apply for grants from Foundations like</h1>
      <div className="-sm:w-[400%] sm:w-[200%] h-20 relative">
        <div className="-sm:w-[400%] sm:w-[200%] flex items-center h-20 justify-around absolute left-0 animate gap-20 animate">
          {logos?.map((i, idx) => {
            return (
              <div
                key={idx}
                className="flex justify-center items-start w-[20rem]"
              >
                <img src={i.img} alt={i.name} />
              </div>
            );
          })}
          {logos?.map((i, idx) => {
            return (
              <div
                key={idx + 20}
                className="flex justify-center items-start w-[20rem]"
              >
                <img src={i.img} alt={i.name} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Logos;
