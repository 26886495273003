import { Center, Spinner } from "@chakra-ui/react";

const Loader = (props) => {
  return (
    <Center>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#B794F4"
        size="xl"
        {...props}
      />
    </Center>
  );
};

export default Loader;
