import { Tooltip, IconButton, useDisclosure } from "@chakra-ui/react";
// import { AiOutlineInfoCircle } from 'react-icons/ai';
// import { FaInfoCircle } from 'react-icons/fa';

interface Props {
  label: string;
  children: React.ReactNode;
  isOpen: boolean;
  // onOpen: () => void;
  // onToggle: () => void;
  // onClose: () => void;
}

export function MobileTooltip(props: Props) {
  // const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { label, children, isOpen } = props;
  return (
    <Tooltip label={label} isOpen={isOpen}>
      <span>
        {children}
        {/* <IconButton
          variant="unstyled"
          minW="none"
          minHeight="auto"
          height="auto"
          aria-label="more info"
          className="flex justify-center"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          onClick={onToggle}
          icon={<FaInfoCircle />}
        /> */}
      </span>
    </Tooltip>
  );
}
