import { useState } from "react";
import { FaArrowRight } from "react-icons/fa/index";
import paperPlane from "@assets/paper-plane.svg";
import { GetStarted } from "./Landing";
import { Dict, GrantRec } from "@utils/interface";
import { useGetRecs } from "@utils/hooks";
import { appName, baseUrl, ellipsis, getOriginUrl } from "@utils/index";
import Loader from "@components/Loader";

const UNKNOWN = "unknown";

const Recommendations = () => {
  const [inputs, setInputs] = useState<Dict>({});

  const handleEntry = (e: React.SyntheticEvent<EventTarget>, type: string) => {
    const value = (e.target as HTMLInputElement).value;
    setInputs({ ...inputs, [type]: value });
  };

  const fetchRecs = useGetRecs(inputs);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchRecs.refetch();
  };

  return (
    <div className={`sm:py-20 bg-primary-light`}>
      <p
        className={`mb-7 text-6xl font-bold text-primary-dark text-center xl:text-left`}
      >
        <span className="gradient-text ">Find your next grant</span>
      </p>
      <div
        className={`-sm:p-2 p-5 flex -xl:flex-col gap-x-10 gap-y-10 bg-white items-center relative`}
      >
        <div className="xl:w-max -xl:mx-auto xl:pr-20 flex-shrink-0 text-left">
          <p className="mb-8 -md:mb-5 text-3xl font-bold xl:leading-[72px]">
            Finding grants made easy!
          </p>
          <p className="-lg:mx-auto mb-10 max-w-[360px] text-lg -md:text-[17px] text-[#475467]">
            Get custom tailored grant recommendations right now, with just your
            organization's info and email.
          </p>
          <GetStarted mxAuto={false} />
        </div>
        {
          // true
          fetchRecs?.data ? (
            <Results
              inputs={inputs}
              recs={fetchRecs?.data}
              // recs={[] || sampleRecs}
            />
          ) : (
            <SearchBox
              inputs={inputs}
              isLoading={fetchRecs?.isFetching}
              handleEntry={handleEntry}
              handleSearch={handleSearch}
            />
          )
        }
      </div>
    </div>
  );
};

interface ResultsProps {
  recs: GrantRec[];
  inputs: Dict;
}

interface SearchBoxProps {
  handleEntry: (e: React.SyntheticEvent<EventTarget>, type: string) => void;
  handleSearch: (e) => void;
  inputs: Dict;
  isLoading?: boolean;
}

const Results = (props: ResultsProps) => {
  const { recs, inputs } = props;

  return (
    <div
      className={`xl:w-1/2 -xl:w-full relative text-left pt-5 pb-8 px-8 -sm:px-6 xl:absolute xl:right-[45px] ${
        recs?.length > 2 ? "xl:-top-[140px]" : ""
      } bg-white [box-shadow:0px_32px_64px_-12px_rgba(16,24,40,0.14)]`}
    >
      <p className="mb-4 text-2xl font-semibold">
        Recommendations{" "}
        <span className="text-primary-dark">
          {inputs?.name ? "for " + inputs?.name : ""}
        </span>
      </p>
      {!recs?.length ? (
        <>
          <p className="text-xl">
            Sorry, no recommendations found for your organization. Don't want to
            leave you empty-handed so as a thank you for trying this out,
            checkout{" "}
            <a
              href="https://www.causeiq.com/directory/grants/?utm_source=www.grantwriteai.com"
              target="blank"
              className="text-primary-dark font-semibold"
            >
              this directory of grants
            </a>
            .
          </p>
          {/* <a
            href={`${baseUrl.landing2}/#pricing`}
            className="mt-2 flex-center-y gap-2 text-md font-semibold text-[#553C9A]"
          >
            <span>Get Started</span>
            <FaArrowRight />
          </a> */}
        </>
      ) : null}
      {recs?.length ? (
        <>
          <div className="grid grid-cols-2 -md:grid-cols-1 gap-y-5 gap-x-6">
            {recs?.map((grant, idx) => {
              return (
                <div
                  className="bg-white px-3.5 py-4 rounded-[11.2px] [box-shadow:0px_7.987px_47.919px_0px_rgba(38,45,118,0.08)]"
                  key={idx}
                >
                  <div className="mb-3 flex flex-wrap gap-3 text-[9.5px] font-medium text-[#344054]">
                    {grant?.tags
                      .split(",")
                      .slice(0, 3)
                      ?.map((tag, index) => {
                        return (
                          <span
                            className="w-max px-3 py-1.5 bg-[#D1FADF] rounded-[13px] text-[8px]"
                            key={index}
                          >
                            {tag.toUpperCase()}
                          </span>
                        );
                      })}
                  </div>
                  <p className="mb-2.5 text-[11.2px] text-[#777795]">
                    {(!grant?.deadline || grant?.deadline?.toLowerCase()) ===
                    UNKNOWN
                      ? "Rolling Deadline"
                      : grant?.deadline}
                  </p>
                  <p className="mb-2.5 text-[14.3px] font-bold text-[#344054]">
                    <a href={getOriginUrl(grant?.link)} target="blank">
                      {grant?.name}{" "}
                    </a>
                  </p>
                  <p className="mb-2.5 text-[9.6px] font-bold text-[#DC6803]">
                    {(!grant?.amount || grant?.amount?.toLowerCase()) ===
                    UNKNOWN
                      ? "$$$"
                      : grant?.amount}
                  </p>
                  <div>
                    <p className="text-[9.6px] font-bold text-[#344054]">
                      About
                    </p>
                    <p className="text-[8px] text-[#4D4D4D]">
                      {ellipsis(grant?.description, 0)}
                    </p>
                  </div>
                  <a
                    href={`${baseUrl.landing2}/#pricing`}
                    className="mt-1.5 flex-center-y gap-2 text-[11.2px] font-semibold text-[#553C9A]"
                  >
                    <span>Get Started</span>
                    <FaArrowRight />
                  </a>
                </div>
              );
            })}
          </div>
          <p className="text-primary-dark font-semibold text-xs mt-2">
            <em>note: links may be moved or expired</em>
          </p>
        </>
      ) : null}
    </div>
  );
};

const SearchBox = (props: SearchBoxProps) => {
  const { handleEntry, inputs, handleSearch, isLoading } = props;

  return (
    <div
      className={`xl:w-1/2 relative text-left pt-5 pb-5 sm:px-8 -sm:px-6 xl:absolute xl:right-[45px] xl:-top-[50px] bg-white [box-shadow:0px_32px_64px_-12px_rgba(16,24,40,0.14)]`}
    >
      {/* <div className="w-full -xl:max-w-[700px] mx-auto py-16 px-8 -sm:px-6 flex-grow rounded-[5.5px] bg-white [box-shadow:0px_24px_48px_-12px_rgba(16,24,40,0.18)]"> */}
      <div>
        <p className="mb-7 text-2xl font-bold text-primary-dark text-center">
          Discover grants tailored for your organization
        </p>
        <form onSubmit={handleSearch}>
          <input
            required
            // autoFocus
            onChange={(e) => handleEntry(e, "name")}
            value={inputs?.name}
            className="w-full mb-5 px-3 py-2.5 block text-[11.5px] font-medium text-[#667085] bg-[#F9FAFB] border border-[#D0D5DD] rounded-[7px] outline-none"
            placeholder="Organization Name*"
            type="text"
            name="name"
            id="name"
          />
          <input
            required
            onChange={(e) => handleEntry(e, "website")}
            value={inputs?.website}
            className="w-full mb-5 px-3 py-2.5 block text-[11.5px] font-medium text-[#667085] bg-[#F9FAFB] border border-[#D0D5DD] rounded-[7px] outline-none"
            placeholder="Organization Website*"
            type="url"
            name="website"
            id="website"
          />
          <div className=" mb-5 relative rounded-[7px] bg-slate-500 border border-[#D0D5DD] overflow-hidden">
            <input
              required
              onChange={(e) => handleEntry(e, "email")}
              value={inputs?.email}
              className="w-full px-3 py-2.5 block text-[11.5px] font-medium text-[#667085] bg-[#F9FAFB] outline-none"
              placeholder="Email Address*"
              type="email"
              name="email"
              id="email"
            />
            <button
              className={`h-full w-10 pt-1 flex-center absolute top-0 right-0 bg-secondary-light hover:bg-secondary-dark`}
              type="submit"
            >
              {isLoading ? (
                <Loader size={"sm"} />
              ) : (
                <img src={paperPlane} alt={`Find grants on ${appName}`} />
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="mt-10 text-center">
        <p className="mb-6 font-bold text-[#475467]">Discover grants for...</p>
        <div className="text-xs font-medium text-[#9F7AEA]">
          <div className="-sm:hidden">
            <div className="mb-3 flex-center gap-6">
              <span>Nonprofits</span>
              <span>Capital funding</span>
              <span>Social Charities</span>
            </div>
            <div className="flex-center gap-6">
              <span>Community Centers</span>
              <span>Health and Wellness</span>
              <span>Environmental Projects</span>
            </div>
          </div>
          <div className="sm:hidden">
            <div className="mb-3 flex justify-center gap-6 -sm:flex-wrap">
              <span>Nonprofits</span>
              <span>Capital funding</span>
            </div>
            <div className="mb-3 flex justify-center gap-6 -sm:flex-wrap">
              <span>Social Charities</span>
              <span>Community Centers</span>
            </div>
            <div className="flex justify-center gap-6 -sm:flex-wrap">
              <span>Health and Wellness</span>
              <span>Environmental Projects</span>
            </div>
          </div>
          <div className="mt-5 text-[#475467]">
            <span>...and much more!</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommendations;

const sampleRecs = [
  {
    tags: "Non-Profit",
    deadline: "Rolling Deadline",
    name: "Chatlos Foundation Grant",
    amount: "Up to US $10,OOO",
    description:
      "The Chatlos Foundation proclaims the Glory of God by funding nonprofit organizations doing work in the United States and around the...",
    link: "https://www.sba.gov/offices/headquarters/wbo/resources/1465581",
  },
  {
    tags: "Non-Profit",
    deadline: "Rolling Deadline",
    name: "A.O Smith Foundation",
    amount: "Unspecified Amount",
    description:
      "The A. O. Smith Foundation is a 501(c)(3), supported by donations from the profits of A. O. Smith Corporation, which funds nonprofit...",
    link: "",
  },
  {
    tags: "Non-Profit",
    deadline: "August 1, 2024",
    name: "JustPax Fund Grant",
    amount: "Unspecified Amount",
    description:
      "The JustPax Fund seeks to support projects that offer a new paradigm, a more gracious path forward...",
    link: "",
  },
  {
    tags: "Non-Profit, Indigenous Group",
    deadline: "July 1, 2024",
    name: "Doyle Foundation Grants",
    amount: "Up to US $67,OOO",
    description:
      "The Doyle Foundation, Inc. awards grants for the betterment of life.Over the years, the foundation expanded the scholarship...",
    link: "",
  },
];
