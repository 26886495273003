import React from "react";
import { isDev, notifySlack } from "./utils";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <ReactErrorBoundary
      onError={(error, info) => {
        if (!isDev)
          notifySlack(`ErrorBoundary: ERROR:${JSON.stringify(error.stack)}\n`);
        // Update state to indicate an error has occurred
      }}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <div className="flex items-center justify-center h-screen">
          <div className="flex flex-col items-center max-w-[700px]">
            <h1 className="text-center text-6xl">OOPS!</h1>
            <div className="px-2">
              <h3 className="font-bold text-center text-3xl text-wrap">
                {isDev ? error?.message : "Something went wrong."}
              </h3>
              <p className="text-lg md:text-2xl text-center">
                Please, try reloading the page!
              </p>
            </div>
            <button
              className="m-4 p-2 border-purple-300 border-2 rounded-xl bg-purple-500 text-white"
              onClick={() => {
                resetErrorBoundary();
                window.location.reload();
              }}
            >
              Reload Page
            </button>
          </div>
        </div>
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
