import { ReactNode, useEffect, useRef } from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Button,
  Flex,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa/index";
// import { StripePricingTable } from "./PricingTable";
import {
  customPrice,
  fetchApi,
  getWindow,
  isDev,
  notifySlack,
  priceV1,
  priceV2,
  primaryColor,
  setURLQuery,
  useColorMode,
  useFlags,
  useQueryParams,
  useReadLocalStore,
} from "@utils/index";
import { StripePricingTable } from "@components/StripePricingTable";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorMode("gray.200", "gray.500")}
      borderRadius={"xl"}
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
    >
      {children}
    </Box>
  );
}

const btnColor = primaryColor;
const checkMarkColor = primaryColor;

export const Pricing = () => {
  const {
    invite,
    live: isLive,
    pv0: isOldPrice,
  } = useQueryParams<boolean | string>();

  const navigate = useNavigate();
  const query = useReadLocalStore<string>("query");
  const planData = isOldPrice ? priceV1 : priceV2;
  const hasRan = useRef(false);
  const { data: abTest, isLoading, isError } = useFlags("LANDING_AB");

  // if (isLoading) {
  //   return (
  //     <Center mt={"20%"}>
  //       <Spinner
  //         thickness="4px"
  //         speed="0.65s"
  //         emptyColor="gray.200"
  //         color="#B794F4"
  //         size="xl"
  //       />
  //     </Center>
  //   );
  // }

  useEffect(() => {
    if (!isLive) {
      setURLQuery(
        !abTest?.isEnabled ? { access: "waitlist" } : { access: null }
      );
    }
    if (!isDev && !hasRan?.current && (isLive || abTest?.isEnabled)) {
      const search = new URLSearchParams(
        getWindow()?.location.search
      ).toString();
      // console.log("search", search);
      notifySlack(
        `Live pricing page shown with: "${search || query}" to: "${
          getWindow()?.navigator?.userAgent
        }"`,
        process.env.SLACK_ACTIVITY_URL
      );
      hasRan.current = true;
    }
  }, [abTest]);

  return (
    <Box py={12} pt={{ base: 40 }}>
      <div className="text-center px-16 space-2">
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={"gray.500"}>
          Start with 7-day free trial. Cancel at anytime.
        </Text>
      </div>
      <div id="pricing"></div>
      {isLoading ? (
        <Center mt={"20%"}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#B794F4"
            size="xl"
          />
        </Center>
      ) : // !isDev &&
      !invite && (isLive || abTest?.isEnabled) ? (
        <div className="pt-20">
          <StripePricingTable />
        </div>
      ) : (
        <Stack
          // flexWrap={{ base: "wrap", md: "nowrap" }}
          // gridGap={10}
          // justify="center"
          direction={{ base: "column", md: "row" }}
          textAlign="center"
          justify="center"
          spacing={{ base: 10 }}
          pl={20}
          pr={20}
          py={10}
        >
          {Object.keys(planData)
            .sort()
            .map((item, idx) => {
              const plan = planData[item];
              // //remove old plan/price query
              // const url = new URL(window?.location?.href);
              // url.searchParams.delete("plan");
              // url.searchParams.delete("price");
              // const newQuery = url.searchParams.toString();

              // const urlQuery = newQuery
              //   ? `?${newQuery}&${planQuery}`
              //   : `?${planQuery}`;

              return (
                <PriceWrapper key={idx}>
                  <Box position="relative">
                    {plan?.mostPopular && (
                      <Box
                        position="absolute"
                        top={{ base: "-36px", md: "-16px" }}
                        left="50%"
                        style={{ transform: "translate(-50%)" }}
                      >
                        <Text
                          textTransform="uppercase"
                          bg={`${btnColor}.300`}
                          px={3}
                          py={1}
                          color={"gray.900"}
                          fontSize="sm"
                          fontWeight="600"
                          rounded="xl"
                        >
                          Most Popular
                        </Text>
                      </Box>
                    )}
                    <Box py={4} px={12}>
                      <Text fontWeight="500" fontSize="2xl">
                        {plan?.title}
                      </Text>
                      <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">
                          $
                        </Text>
                        <Text fontSize="5xl" fontWeight="900">
                          {plan?.price}
                        </Text>
                        <Text fontSize="3xl" color="gray.500">
                          /month
                        </Text>
                      </HStack>
                    </Box>
                    <p className="text-xs italic w-full pb-3">
                      {plan?.bestFor || ""}
                    </p>
                    <div className="bg-gray-50 py-4 rounded-b-xl">
                      <List
                        spacing={3}
                        textAlign="start"
                        px={5}
                        pb={plan?.pb || 0}
                      >
                        <ListItem>{plan?.tag || ""}</ListItem>
                        {plan?.features?.map((feature, idx) => {
                          return (
                            <ListItem key={idx}>
                              <ListIcon
                                as={FaCheckCircle}
                                color={`${checkMarkColor}.500`}
                              />
                              {feature}
                            </ListItem>
                          );
                        })}
                      </List>
                      <div className="w-[80%] ml-7">
                        <Button
                          w="full"
                          as={"a"}
                          // href={`/signup${urlQuery}`}
                          onClick={() => {
                            const planQuery = `${query}&plan=${plan?.title.toLowerCase()}&price=${
                              plan?.price
                            }`;
                            navigate(`/signup${planQuery}`);
                          }}
                          colorScheme={btnColor}
                          variant={plan?.mostPopular ? "solid" : "outline"}
                        >
                          Start trial
                        </Button>
                      </div>
                    </div>
                  </Box>
                </PriceWrapper>
              );
            })}

          <PriceWrapper>
            <Box py={4} px={12}>
              {/* <Text fontWeight="500" fontSize="2xl">
              </Text> */}
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="900">
                  {customPrice?.title}
                </Text>
              </HStack>
            </Box>
            <div className="bg-gray-50 py-4 rounded-b-xl">
              <List
                spacing={3}
                textAlign="start"
                px={5}
                pb={customPrice?.pb || 0}
              >
                <ListItem>{customPrice?.tag}</ListItem>
                {customPrice?.features?.map((feature, idx) => {
                  return (
                    <ListItem key={idx}>
                      <ListIcon
                        as={FaCheckCircle}
                        color={`${checkMarkColor}.500`}
                      />
                      {feature}
                    </ListItem>
                  );
                })}
              </List>
              <div className="w-[80%] ml-7">
                <Button
                  w="full"
                  as={"a"}
                  href={"/contact"}
                  colorScheme={btnColor}
                  variant="outline"
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </PriceWrapper>
        </Stack>
      )}
    </Box>
  );
};

export default Pricing;
