import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
// import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  FormValue,
  appName,
  clearForm,
  fetchApi,
  getWindow,
  includesString,
  isDev,
  APP_CONST,
  notifySlack,
  primaryColor,
  useQueryParams,
  useReadLocalStore,
} from "@utils/index";
import { useNavigate } from "react-router-dom";
import Pricing from "./landing/old/Pricing";
import Loader from "./Loader";
import { useQuery } from "react-query";

export default function Signup() {
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [optionalData, setOptionalData] = useState<Record<string, string>>({});
  const query = useReadLocalStore<string>("query");
  const { invite } = useQueryParams<string>(query || "");
  const [canSendMail, setCanSendMail] = useState(false);
  const [apiResp, setApiResp] = useState({ message: "" });

  const sendMailer = async () =>
    await fetchApi(new URL("/mailer", window.location.origin).href, "POST", {
      body: JSON.stringify(formData),
    });

  const { isLoading } = useQuery({
    queryKey: ["signup"],
    queryFn: sendMailer,
    enabled: !!getWindow() && canSendMail,
    onSuccess: (data) => {
      setApiResp(data);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const blacklistedEmailDomains = [".ru"];

    if (includesString(blacklistedEmailDomains, formData.email)) {
      setApiResp({ message: "blacklisted" });
      return;
    }

    setCanSendMail(true);
    if (!isDev)
      notifySlack(
        {
          action: "waitlist_signup",
          ...formData,
          ...optionalData,
          query,
          search: new URLSearchParams(getWindow()?.location?.search).toString(),
        },
        process.env.SLACK_WAITLIST_URL
      );
    // const emailService = target.email.value.split("@")[1];
    // window.location.href = `https://${emailService}`;
    // console.log("email ", target.email.value);
  };

  if (apiResp?.message) {
    if (canSendMail) setCanSendMail(false);
    // console.log("data", data);
    return (
      <div className="flex justify-center w-content h-content mt-20 mb-20">
        <div className=" border rounded-lg border-gray-300 text-center p-10">
          <h2 className="text-2xl font-bold">
            A welcome email has been sent to{" "}
            <span className="text-purple-500">
              {formData?.email || "your email"}
            </span>
            !
          </h2>
          <p className="pt-5 text-xl font-normal">
            Please check your email for your welcome email.{" "}
          </p>
          <p className="pt-2 italic font-bold text-sm text-purple-400">
            (Double check your spam folder and mark email as "NOT SPAM" in case)
          </p>
          <p className="text-sm">
            Reach out to{" "}
            <a href="mailto:team@grantwriteai.com" className="text-purple-500">
              <em>team@grantwriteai.com</em>
            </a>{" "}
            if none of the above work.{" "}
          </p>
          <p className="pt-5">
            Thank you for choosing{" "}
            <span className="text-purple-500">{appName}</span>.
          </p>
        </div>
      </div>
    );
  }

  const canSubmit =
    Object.keys(formData).length >= 3 &&
    Object.values(formData).every((val) => val !== "");

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      // bg={useColorModeValue("gray.50", "gray.800")}
      className="bg-white"
    >
      <form onSubmit={handleSubmit}>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              Sign up
            </Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              to enjoy all of our cool features ✌️
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          firstName: e.target.value.trim(),
                        }))
                      }
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          lastName: e.target.value.trim(),
                        }))
                      }
                    />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter a valid email to get access"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      email: e.target.value.trim(),
                    }))
                  }
                />
              </FormControl>
              <input
                className="input border-gray-100 border-2 rounded-md p-2 w-full"
                type="text"
                placeholder="How'd you hear about us?*"
                value={
                  optionalData?.discovery ||
                  (invite ? decodeURIComponent(invite) : "")
                }
                onChange={(e) =>
                  setOptionalData((prev) => ({
                    ...prev,
                    discovery: e.target.value.trim(),
                  }))
                }
              />
              <textarea
                className="input border-gray-100 border-2 rounded-md p-2 w-full text-md resize-none"
                placeholder={`You're interested in something like ${appName} because?*`}
                rows={2}
                value={optionalData?.interested || ""}
                onChange={(e) =>
                  setOptionalData((prev) => ({
                    ...prev,
                    interested: e.target.value,
                  }))
                }
              />
              {/* <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input type={showPassword ? "text" : "password"} />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl> */}
              <Stack spacing={10} pt={2}>
                <Button
                  // loadingText="Submitting"
                  // size="lg"
                  // bg={`${primaryColor}.500`}
                  // color={"white"}
                  isDisabled={!canSubmit || isLoading}
                  // _hover={{
                  //   bg: `${primaryColor}.500`,
                  // }}
                  // onClick={handleSubmit}
                  type="submit"
                  className={`${
                    !canSubmit || isLoading ? "cursor-default" : ""
                  } bg-${primaryColor}-500 hover:bg-${primaryColor}-800 text-white w-full mt-4`}
                >
                  {isLoading ? <Loader size={"md"} /> : "Sign up"}
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"}>
                  Already a user?{" "}
                  <Link
                    color={`${primaryColor}.400`}
                    href={`${APP_CONST.loginUrl}${query || ""}`}
                  >
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  );
}
