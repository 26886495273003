import React from "react";
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  Container,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { appName, primaryColor, useColorMode } from "@utils/index";
import grantBoard from "@assets/grant-board.png";

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps["marginTop"];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={"md"} variant="solid" colorScheme={primaryColor} key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const About = () => {
  return (
    <Container maxW={"7xl"} p="12">
      <Heading as="h1" textAlign={"center"}>
        Introducing {appName}:
      </Heading>
      <Text as="p" textAlign={"center"} fontSize="lg">
        Your Ultimate Grant Writing Sidekick!
      </Text>
      <Box
        display="flex"
        flex="1"
        marginRight="3"
        position="relative"
        alignItems="center"
      >
        <Box
          width={{ base: "100%", sm: "85%" }}
          zIndex="2"
          marginLeft={{ base: "0", sm: "5%" }}
          marginTop="5%"
        >
          <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
            <Image
              borderRadius="lg"
              src={grantBoard}
              // width="50%"
              backdropBlur={2}
              alt="some good alt text"
              objectFit="contain"
            />
          </Link>
        </Box>

        <Box zIndex="1" width="100%" position="absolute" height="100%">
          <Box
            bgGradient={useColorModeValue(
              `radial(${primaryColor}.600 1px, transparent 1px)`,
              `radial(${primaryColor}.300 1px, transparent 1px)`
            )}
            backgroundSize="20px 20px"
            opacity="0.4"
            height="100%"
          />
        </Box>
      </Box>

      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Text as="p" lineHeight={10} fontSize="lg">
          {`Tired of sifting through mountains of data and struggling to craft winning grant proposals? Say hello to your grant writing superhero! ${appName} is here to revolutionize your grant writing process, making it faster, smarter, and more efficient than ever.`}
        </Text>
        <br />
        <Text as="p" lineHeight={10} fontSize="lg">
          {`With the power of cutting-edge artificial intelligence, ${appName} analyzes vast databases, tailors your proposals to match funders' preferences, and suggests compelling content that captures attention. No more hours wasted on research and guesswork – now you can focus on what truly matters: telling your organization's inspiring story.`}
        </Text>
        <br />
        <Text as="p" lineHeight={10} fontSize="lg">
          {`Watch your proposals soar to new heights as ${appName} supercharges your team's creativity, streamlines collaboration, and turns complex data into persuasive narratives. Say goodbye to stress and hello to success, with a grant writing partner that's always a step ahead.`}
        </Text>
        <br />
        <Text as="p" lineHeight={10} fontSize="lg">
          {`Unleash the potential of your grant writing dreams with ${appName}. Elevate your impact, secure funding faster, and achieve your mission like the true hero you are. Try ${appName} today and watch your grants take flight!`}
        </Text>
        <br />
        <Text as="p" lineHeight={10} fontSize="lg">
          {`If you have any questions or need assistance, please don't hesitate to get in touch with us. We're here to help you every step of the way.`}
        </Text>
      </VStack>
    </Container>
  );
};

export default About;
