import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Tooltip,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { SyntheticEvent } from "react";
import { BsPerson } from "react-icons/bs/index";
import { MdEmail, MdOutlineEmail } from "react-icons/md/index";
import {
  clearForm,
  CONFETTI_DARK,
  CONFETTI_LIGHT,
  FormValue,
  notifySlack,
  primaryColor,
} from "@utils/index";

export default function Contact() {
  // const { hasCopied, onCopy } = useClipboard("example@example.com");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as typeof e.target & { [key: string]: FormValue };
    notifySlack({
      name: form.name.value,
      email: form.email.value,
      message: form.message.value,
      discovery: form.discovery.value,
    });
    alert("Message sent! Thanks!");
    clearForm("contact-form");
  };

  return (
    <Flex
      bg={useColorModeValue(`${primaryColor}.100`, `${primaryColor}.900`)}
      align="center"
      justify="center"
      css={{
        backgroundImage: useColorModeValue(CONFETTI_LIGHT, CONFETTI_DARK),
        backgroundAttachment: "fixed",
      }}
      id="contact"
    >
      <div className="rounded-lg m-5 p-5 md:m-16 lg:m-10">
        <div className="m-4 md:m-8 lg:m-20 flex flex-col text-center">
          <p className="text-4xl md:text-5xl text-white font-bold">
            Get in Touch
          </p>

          <div className=" m-4 md:m-8 lg:m-20 flex flex-col md:flex-row justify-center items-center">
            <div className="w-[300px] sm:min-w-[500px] bg-white rounded-lg text-gray-700 p-8 shadow-md">
              <form
                id="contact-form"
                onSubmit={handleSubmit}
                className="children:mb-4"
              >
                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>

                  <InputGroup>
                    <InputLeftElement children={<BsPerson />} />
                    <Input type="text" name="name" placeholder="Your Name" />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>

                  <InputGroup>
                    <InputLeftElement children={<MdOutlineEmail />} />
                    <Input type="email" name="email" placeholder="Your Email" />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Message</FormLabel>

                  <Textarea
                    name="message"
                    placeholder="Your Message"
                    rows={6}
                    resize="none"
                  />
                </FormControl>

                <input
                  className="input border-gray-100 border-2 rounded-md p-2 w-full"
                  type="text"
                  placeholder="How'd you hear about us?*"
                  name="discovery"
                />

                <Button
                  // colorScheme={primaryColor}
                  // bg={`${primaryColor}.400`}
                  color="white"
                  // _hover={{
                  //   bg: `${primaryColor}.500`,
                  // }}
                  type="submit"
                  className={`bg-${primaryColor}-500 hover:bg-${primaryColor}-800 w-full mt-4`}
                >
                  Send Message
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Flex>
  );
}
