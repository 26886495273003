// import { useState, useEffect } from 'react';
import Article from "@components/Article";
// import Features from "./Features";
// import Footer from "./Footer";
import Hero from "./Hero";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import grantBoard from "@assets/grant-board.png";
// import inviteTeam from "@assets/invite-team.jpeg";
import orgProfile from "@assets/org-profile.png";
import draftProposal from "@assets/editor-1.png";
import { getWindow, setURLQuery, useFlags } from "@utils/index";
import LandingVideo from "@components/Video";
import { useEffect } from "react";
// import SEO from "@components/SEO";

const Landing = () => {
  const {
    data: abTestVideo,
    isLoading,
    isError,
  } = useFlags("LANDING_AB_VIDEO");

  useEffect(() => {
    setURLQuery(
      abTestVideo?.isEnabled ? { video: "enabled" } : { video: null }
    );
  }, [abTestVideo]);

  return (
    <div>
      <Hero />
      {abTestVideo?.isEnabled && <LandingVideo />}
      <div id="features" style={{ paddingBottom: 50 }}>
        {/* <Features /> */}
        <Article
          img={draftProposal}
          alt="AI Proposal Drafting"
          text={
            "Our AI Proposal drafter is your trusted partner in crafting winning grant proposals. Harnessing the power of artificial intelligence, it assists grant seekers at every step, from generating well-structured narratives to suggesting compelling language. It adapts to diverse grant requirements and ensures adherence to guidelines."
          }
        />
        <Article
          img={grantBoard}
          alt="Grant Tracker"
          flip
          text={
            "Our grant tracker is a game-changer for organizations seeking to streamline their grant workflow. Say goodbye to cumbersome Excel spreadsheets and welcome a more efficient and organized approach to managing grants. With our grant tracker, you can effortlessly collaborate & track the status of grant applications, deadlines, and funding opportunities in real-time."
          }
        />
        <Article
          img={orgProfile}
          alt="Org Profile"
          text={
            "Our organization profile setup feature simplifies the often daunting task of creating and managing detailed organizational profiles. With a user-friendly interface, it streamlines the process, guiding users through key sections such as mission statements, financial information, and impact metrics. Allowing for collaboration, it enables multiple users to contribute to the profile, ensuring that it is always up-to-date."
          }
        />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div>
        <Pricing />
      </div>
    </div>
  );
};

export default Landing;
