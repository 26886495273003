import { appName } from "@utils/index";
import Banner from "@components/Banner";
import SEO from "@components/SEO";
import SSRTest from "@components/ssrTest/SSRTest";
import Footer from "./Footer";
import Landing from "./Landing";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";

const Index = () => {
  return (
    <>
      {/* <SSRTest /> */}
      <Banner
        showOffer={false}
        // text={`${appName} 2.0 is here! 🎉 FIND > TRACK > WRITE grants faster than before!`}
        text={
          <p className="">
            Check out the 2024 Grant Writing Report.{" "}
            <a
              className="underline cursor-pointer"
              href="https://blog.grantwriteai.com/the-comprehensive-report-for-all-things-grant-writing-2024"
              target="_blank" rel="noreferrer"
            >
              Fill out this survey and get access!
            </a>
          </p>
        }
      />
      <Navbar />
      <SEO />
      <Outlet />
      <Footer />
    </>
  );
};

export default Index;
