import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

interface OverlayProps {
  children: React.ReactNode;
  showOverlay?: boolean;
  zIndex?: string;
  onDone?: () => void;
}

const Overlay = ({
  children,
  showOverlay = true,
  onDone,
  zIndex: customZIndex,
}: OverlayProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => onDone && onDone());

  return (
    // <div className="fixed z-[1000] left-0 top-0 w-screen h-screen flex justify-center bg-black bg-opacity-40">
    <div
      className={
        !showOverlay
          ? ""
          : `overflow-y-scroll fixed ${
              customZIndex ? customZIndex : "z-[1001]"
            } left-0 top-0 w-screen h-screen flex justify-center bg-black bg-opacity-40` //flex-center
      }
    >
      <div className="flex-x-center" ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Overlay;
