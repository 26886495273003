// Outlet component
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SEO from "@components/SEO";
import Banner from "@components/Banner";
import { appName } from "@utils/index";

const Index = () => (
  <div>
    <Banner
      showOffer={false}
      text={`${appName} 2.0 is here! 🎉 FIND > TRACK > WRITE grants faster than before!`}
    />
    <Navbar />
    <SEO />
    <Outlet />
    <Footer />
  </div>
);

export default Index;
