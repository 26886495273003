const LandingVideo = () => {
  return (
    <div id="video" style={{ paddingBottom: 50 }}>
      <iframe
        width="100%"
        height="500"
        // https://stackoverflow.com/a/66946714
        src="https://www.youtube.com/embed/_vSfQxqobl4/?listType=playlist&rel=0&loop=1&controls=0"
        title="YouTube video player"
        // frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default LandingVideo;
